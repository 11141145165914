import cx from 'classnames';
import Image from 'components/image';

import { Ratio } from 'components/@tedui';
import Badge from 'components/badge';
import { editorialize } from 'lib/business';

import useVideoPlayerStore from 'components/video-player/store';
import Play from 'icons/PlayIcon';

type CoverProps = {
  badgeContent?: React.ReactNode;
  variant?: 'playable';
  alt?: string;
  children?: React.ReactNode;
  imageUrl: string;
};

function Cover({
  badgeContent = null,
  alt = '',
  variant,
  imageUrl,
  children
}: CoverProps): React.ReactNode {
  const { isEmbed } = useVideoPlayerStore(state => ({
    isEmbed: state.isEmbed
  }));

  return (
    <section className="relative flex size-full items-stretch">
      <Ratio
        ratio={[16, 9]}
        wrapperClassName={cx('w-full', {
          'h-screen max-h-screen min-h-screen': isEmbed
        })}
      >
        <Image
          alt={editorialize(alt)}
          src={imageUrl}
          fill
          priority
          className="object-cover object-top"
          sizes="(max-width: 640px) 100vw, (max-width: 1024px) 75vw, (max-width: 1920px) 1280px, 1920px"
        />
        {(children || badgeContent || variant === 'playable') && (
          <div className="w-full">
            {children && <div className="hidden md:block">{children}</div>}

            {badgeContent && (
              <div className="absolute bottom-2 right-2">
                <Badge>{badgeContent}</Badge>
              </div>
            )}

            {variant === 'playable' && (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
                <div className="scale-100 transform rounded-full bg-white/50 p-1 transition-transform duration-150 ease-out hover:scale-125 md:p-2">
                  <Play variant="default" className="p-1 md:p-2" />
                </div>
              </div>
            )}
          </div>
        )}
      </Ratio>

      {children && (
        <div className="block bg-black text-white md:hidden">{children}</div>
      )}
    </section>
  );
}

export default Cover;
