import type { Topic } from 'api';
import { sendErrorToServices } from 'lib/logging';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';
import storage from 'local-storage-fallback';
import { parseCookies } from 'nookies';

const useSailthruTrack = (
  video: VideoData
): {
  sendPageview: () => Promise<void>;
} => {
  const allCookies = parseCookies();
  const sailthruCookieValue = storage.getItem('sailthru_hid');

  const tags = video.topics.nodes
    .map((topic: Topic) => topic.name)
    .filter(Boolean)
    .join(', ');

  const baseRequestBody = {
    excludeContent: false,
    useStoredTags: true,
    messageId: ''
  };

  const sendPageview = async () => {
    try {
      const response = await fetch(
        'https://api.sail-track.com/v1/track/event/pageview',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.NEXT_PUBLIC_SAILTHRU_KEY}`
          },
          body: JSON.stringify({
            ...baseRequestBody,
            url: `https://www.ted.com/talks/${video.id}`,
            tags,
            ...(sailthruCookieValue && {
              userIdKey: 'hid',
              userIdValue: sailthruCookieValue
            }),
            ...(allCookies.sailthru_visitor && {
              visitorId: allCookies.sailthru_visitor
            })
          })
        }
      );

      if (!response.ok) {
        sendErrorToServices('Sailthru tracking failed', {
          error: new Error('Failed to send pageview to Sailthru'),
          context: {
            response
          }
        });
      }
    } catch (error) {
      sendErrorToServices('Sailthru tracking failed', {
        error,
        context: {
          videoId: video.id
        }
      });
    }
  };

  return {
    sendPageview
  };
};

export default useSailthruTrack;
