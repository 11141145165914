import { useDidMount } from 'lib/hooks/useDidMount';
import storage from 'local-storage-fallback';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

const useSummarySurvey = (): {
  surveyDismissed: boolean;
  onHideSurvey: () => void;
} => {
  const hasMounted = useDidMount();

  const [surveyDismissed, setSurveyDismissed] = useState<boolean>(null);
  const survey = storage.getItem('Summary_surveyDismissed');

  useEffect(() => {
    if (hasMounted) {
      if (isEmpty(survey)) {
        storage.setItem('Summary_surveyDismissed', 'false');
        setSurveyDismissed(false);
      } else {
        setSurveyDismissed(survey !== 'false');
      }
    }
  }, [hasMounted]);

  const onHideSurvey = useCallback(() => {
    storage.setItem('Summary_surveyDismissed', 'true');
    setSurveyDismissed(true);
  }, []);

  return {
    surveyDismissed,
    onHideSurvey
  };
};

export default useSummarySurvey;
