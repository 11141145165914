import cx from 'classnames';

import { Text } from 'components/@tedui';

function Badge({
  children,
  className,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
}): React.ReactNode {
  return (
    <div
      className={cx(
        'inline-block rounded-xs bg-black px-2 py-1 text-white',
        className
      )}
      {...props}
    >
      <Text variant="label">{children}</Text>
    </div>
  );
}

Badge.displayName = 'Zenith(Badge)';

export default Badge;
