// @ts-strict-ignore
import useVideoPlayerStore from 'components/video-player/store';
import { MIXPANEL_PRODUCT } from 'lib/analytics/constants';
import mixpanel from 'lib/analytics/mixpanel';
import { generateTalkTopicsList } from 'lib/analytics/mixpanel-helpers';
import { VideoData } from 'lib/pages/talks/slug/[...slug].props';
import { NextRouter, useRouter } from 'next/router';
import { useCallback, useMemo, useRef } from 'react';

type HookReturn = {
  trackImpression: (impressionName: string) => void;
  trackShare: (options?: unknown) => void;
  genericTrackClick: (eventName: string, options?: unknown) => void;
  genericTrackClickCustomEvent: (eventName: string, options?: unknown) => void;
  trackImpressionCard: (
    action: 'like' | 'deslike' | 'dismiss',
    eventName: string
  ) => void;
};

const getPlaylistReferrer = referrer => {
  if (referrer && referrer.includes('playlist-')) {
    return referrer.split('playlist-')[1];
  }
  return null;
};

const getContentDiscoveryContextType = pathName => {
  if (typeof window !== 'undefined') {
    const contextType = pathName.split('/')[1];

    if (contextType === 'dashboard') {
      const subpage = pathName.split('/')[2];

      if (subpage === 'my-library') {
        const myLibrarySubpage = pathName.split('/')[3];
        return `my-library${myLibrarySubpage ? `-${myLibrarySubpage}` : ``}`;
      }

      return contextType;
    }

    if (contextType === 'talks') {
      return null;
    }

    return contextType;
  }

  return null;
};

const useTrack = (videoData: VideoData): HookReturn => {
  const router = useRouter();
  const routerRef = useRef<NextRouter>(undefined);

  const {
    playbackRate: playback_rate,
    streamHost: stream_host,
    autoplayed,
    subtitle: subtitleLanguage,
    fullscreen,
    roundedTime: action_time
  } = useVideoPlayerStore(state => ({
    ...state
  }));

  routerRef.current = router;

  const isTalksPage = (path: string) => path.startsWith('/talks/');

  const getTalkPageContext = useCallback(() => {
    const contexts = ['Talk'];
    if (routerRef.current.asPath.includes('/transcript')) {
      contexts.push('Transcript');
    }
    if (routerRef.current.asPath.includes('referrer=playlist')) {
      contexts.push('Playlist');
    }
    if (routerRef.current.asPath.includes('rid=')) {
      contexts.push('Recommendation');
    }
    return contexts.join('-'); // Talks-Playlist-Transcript
  }, []);

  const consumeLocalStorage = key => {
    if (typeof window !== 'undefined') {
      const storageValue = window.localStorage.getItem(key);
      window.localStorage.removeItem(key);
      return storageValue || null;
    }
    return null;
  };

  const mixpanelEventProps = useMemo(() => {
    let mixpanelEvent: Record<string, string | string[] | number> = {
      language_parameter_value: routerRef.current?.query?.language || 'en',
      system_language:
        typeof navigator !== 'undefined' ? navigator?.language : null,
      navigation_context: consumeLocalStorage('navigation_context'),
      content_discovery_context:
        consumeLocalStorage('content_discovery_context') || null,
      content_discovery_page_type: getContentDiscoveryContextType(
        routerRef.current?.pathname
      ),
      cta_impression_context: consumeLocalStorage('cta_impression_context')
    };

    if (
      routerRef.current.asPath.includes('/playlists/') &&
      routerRef.current?.query?.id
    ) {
      mixpanelEvent = {
        ...mixpanelEvent,
        playlist_id: routerRef.current.query.id
      };
    }

    if (isTalksPage(routerRef.current.asPath)) {
      mixpanelEvent = {
        ...mixpanelEvent,
        talk_id: videoData?.id ? String(videoData?.id) : null,
        talk_type_id: videoData?.type?.id || null,
        talk_type_name: videoData?.type?.name || null,
        talk_type: videoData?.type?.name || null,
        talk_page_view_context: getTalkPageContext() || null,
        talk_slug: routerRef.current?.query?.slug // have to do it this way to suppress some test warnings not finding it. mainly just output cleanup
          ? routerRef.current.query.slug[0]
          : null,
        talk_language: videoData?.audioInternalLanguageCode || null,
        recommendation_id:
          (routerRef.current?.query && routerRef.current?.query.rid) || null,
        playlist_slug: getPlaylistReferrer(routerRef?.current?.query?.referrer),
        video_duration: videoData?.duration || null,
        video_topics: generateTalkTopicsList(videoData?.topics),
        video_post_date: videoData?.publishedAt || null,
        talkstar_context: videoData?.videoContext || null,
        subtitle_language: subtitleLanguage || null,
        autoplay_state: `${autoplayed}`,
        media_type: videoData?.__typename || null,
        stream_host: stream_host || null,
        playback_rate,
        action_time,
        player_presentation: fullscreen ? 'fullscreen' : 'inline'
      };
    }
    return mixpanelEvent;
  }, [getTalkPageContext, subtitleLanguage, autoplayed, videoData]);

  // Product and engineering can view volume of impressions
  const trackImpression = (impressionName: string) => {
    return mixpanel.track('component_impression', {
      product: MIXPANEL_PRODUCT,
      component: impressionName,
      ...mixpanelEventProps
    });
  };

  const genericTrackClick = (eventName, options = {}) => {
    return mixpanel.track('component_click', {
      product: MIXPANEL_PRODUCT,
      component: eventName,
      ...options,
      ...mixpanelEventProps
    });
  };

  const genericTrackClickCustomEvent = (eventName, options = {}) => {
    return mixpanel.track(eventName, {
      product: MIXPANEL_PRODUCT,
      ...options,
      ...mixpanelEventProps
    });
  };

  // Product and engineering can view how many quotes were shared/Product and engineering can view which quotes were shared
  const trackImpressionCard = (
    action: 'like' | 'deslike' | 'dismiss',
    eventName: string
  ) => {
    return mixpanel.track('component_click', {
      product: MIXPANEL_PRODUCT,
      component: `${eventName} ${action}`,
      ...mixpanelEventProps
    });
  };

  const trackShare = (options = {}) => {
    return mixpanel.track('share_start', {
      product: MIXPANEL_PRODUCT,
      sharing_service: `AI Quote Card (OS-specific)`,
      ...options,
      ...mixpanelEventProps
    });
  };

  return {
    trackImpression,
    genericTrackClick,
    trackImpressionCard,
    trackShare,
    genericTrackClickCustomEvent
  };
};

export default useTrack;
