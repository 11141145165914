import useVideoPlayerStore from 'components/video-player/store';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import shallow from 'zustand/shallow';

type UsePlayNextTalkProps = {
  setSourceContext: (sourceContext: string) => void;
};

const usePlayNextTalk = ({ setSourceContext }: UsePlayNextTalkProps) => {
  const router = useRouter();
  const { expressPlaybackIntent, reset } = useVideoPlayerStore(
    state => ({
      expressPlaybackIntent: state.expressPlaybackIntent,
      reset: state.reset
    }),
    shallow
  );
  const playNextTalk = useCallback(
    () => () => {
      setSourceContext('talks-related');

      const handleRouteChangeComplete = () => {
        reset('nextTalk');
        expressPlaybackIntent(true);

        router.events.off('routeChangeComplete', handleRouteChangeComplete);
      };

      router.events.on('routeChangeComplete', handleRouteChangeComplete);
    },
    [router, setSourceContext, reset, expressPlaybackIntent]
  );

  return {
    playNextTalk
  };
};

export default usePlayNextTalk;
