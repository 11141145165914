import { useIsBreakpointWidth } from 'lib/hooks/useIsBreakpointWidth';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

const HEADER_HEIGHT = 56.19;
const PADDING_INTERVAL = 25;

const DEFAULT_VALUE = {
  right: 0,
  left: 0
};

type HookReturn = {
  videoRef: MutableRefObject<HTMLDivElement>;
  detailsRef: MutableRefObject<HTMLDivElement>;
  actionButtonsVisibilityPadding: {
    right: number;
    left: number;
  };
};

const useTalkPaddingOnResize = (): HookReturn => {
  const videoRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsBreakpointWidth({ size: `lg` });

  const [actionButtonsVisibilityPadding, setActionButtonsVisibilityPadding] =
    useState<{ right: number; left: number }>(DEFAULT_VALUE);
  const [previousSize, setPreviousSize] = useState({
    height: 0,
    width: 0
  });

  const handleResize = useCallback(() => {
    // Only apply padding logic for mobile landscape view
    if (!isMobile) {
      setActionButtonsVisibilityPadding(DEFAULT_VALUE);
      return;
    }

    try {
      const screenHeight = window.innerHeight;
      const screenWidth = window.innerWidth;
      const detailsRect = detailsRef.current?.getBoundingClientRect();
      if (detailsRect) {
        const videoHeight = videoRef.current?.clientHeight ?? 0;
        const detailsHeight = detailsRect?.height ?? 0;
        const totalHeight = HEADER_HEIGHT + videoHeight + detailsHeight;
        const diffRatio = totalHeight / screenHeight;
        const notVisible = totalHeight > screenHeight;
        // Set padding whenever the sreen first loaded
        if (
          notVisible &&
          actionButtonsVisibilityPadding.right === 0 &&
          !isMobile
        ) {
          setActionButtonsVisibilityPadding({
            right: (totalHeight - screenHeight) * 1.25,
            left: (totalHeight - screenHeight) * 1.25
          });
        } else if (diffRatio < 0.85 || isMobile) {
          setActionButtonsVisibilityPadding(DEFAULT_VALUE);
        } else if (
          // handle screen height decrease
          diffRatio > 0.98 &&
          (previousSize.height > screenHeight ||
            previousSize.width < screenWidth)
        ) {
          setActionButtonsVisibilityPadding(({ right, left }) =>
            right <= 300
              ? {
                  right: right + PADDING_INTERVAL,
                  left:
                    right < 200
                      ? left + PADDING_INTERVAL
                      : Math.round(
                          (right + PADDING_INTERVAL) / 1.5 / PADDING_INTERVAL
                        ) * PADDING_INTERVAL
                }
              : { right, left }
          );
        } else if (
          // handle screen height increase
          diffRatio < 0.96 &&
          (previousSize.height < screenHeight ||
            previousSize.width > screenWidth)
        ) {
          setActionButtonsVisibilityPadding(({ right, left }) => {
            const leftValue = left > 0 ? left - PADDING_INTERVAL / 1.5 : 0;
            const rightValue =
              right === left
                ? right > 0
                  ? right - PADDING_INTERVAL / 1.5
                  : 0
                : leftValue;
            return {
              right: rightValue,
              left: leftValue
            };
          });
        }
        if (
          // update screen size for comparisons
          screenHeight !== previousSize.height ||
          screenWidth !== previousSize.width
        ) {
          setPreviousSize({
            height: screenHeight,
            width: screenWidth
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [previousSize, isMobile, actionButtonsVisibilityPadding]);

  useEffect(() => {
    handleResize();
    document.addEventListener('visibilitychange', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('visibilitychange', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    videoRef,
    detailsRef,
    actionButtonsVisibilityPadding
  };
};

export default useTalkPaddingOnResize;
